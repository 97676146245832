<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del log"
      ref="pageForm"
      :items="items">
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  El 'Round ID' está casado con las rondas virtuales generadas
                  para cada ruleta virtual.
                </p>
                <p>
                  Acá puedes observar el ID del proveedor, y el ID que envia
                  para la ronda.
                </p>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.from") }}: </span>
                  <vs-input
                    v-model="filterData.fromDate"
                    type="date"></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.until") }}: </span>
                  <vs-input v-model="filterData.toDate" type="date"></vs-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <!-- <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Cliente:
                  <select
                    v-model="filterData.clientId"
                    @change="change"
                    name="client"
                    class="form-select form-select-sm m-2"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option
                      v-for="({ _id, name }, index) in clients"
                      :key="index"
                      :value="_id"
                    >
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div> -->
              <!-- <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Operador:
                  <select
                    v-model="filterData.operatorId"
                    @change="change"
                    name="client"
                    class="form-select form-select-sm m-2"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div> -->
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                v-if="userRole !== 'OPERATOR'">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione..">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option
                    v-for="(item, index) in gameTypes"
                    :key="index"
                    :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="filterData.gameType === 'Roulette'">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.roulette") }}:
                  <select
                    v-model="filterData.rouletteId"
                    @change="change"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="({ providerId, name }, index) in roulettes"
                      :key="index"
                      :value="providerId">
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
              <!-- <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100">
                  Moneda:
                  <select
                    v-model="filterData.currencyId"
                    @change="change"
                    name="currency"
                    class="form-select form-select-sm m-2"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option
                      v-for="({ _id, name }, index) in currencies"
                      :key="index"
                      :value="_id"
                    >
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div> -->
            </div>
            <div class="d-flex align-items-center mt-2">
              <!-- <div>
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="true"
                  class="me-4"
                  ><span class="ms-2 p-0"
                    >Descargar registros actuales</span
                  ></b-form-radio
                >
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="false"
                  ><span class="ms-2 p-0"
                    >Descargar todos los registros</span
                  ></b-form-radio
                >
              </div> -->
              <vs-button>
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName">
                  {{ $t("filter.downloadExcel") }}
                </downloadexcel>
              </vs-button>
              <DateRange @handleRadio="handleRadio" />
              <vs-button type="button" @click="filtrar()">
                {{ $t("filter.filter") }}</vs-button
              >
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length">
                {{ $t("filter.cancel") }}
              </vs-button>
            </div>

            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                      @input="handleSearch"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <TotalRegister :totalRegister="totalRegister"></TotalRegister> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc">
                  <template #cell(dateFormatted)="data">
                    <div
                      class="createdAt"
                      @mouseover="handleOver"
                      @mouseleave="handleLeave">
                      {{
                        moment(data.item.createdAt).format(
                          "DD/MM/YYYY | HH:mm:ss"
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill"
                        >Server time:
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format("DD/MM/YYYY | HH:mm:ss")
                        }}</span
                      >
                    </div>
                  </template>
                  <template #cell(round)="data">{{
                    data.item.roundProvider.length
                      ? data.item.roundProvider[0].identifierNumber
                      : "No round"
                  }}</template>
                  <template #cell(title)="data">{{
                    data.item.title ? data.item.title : ""
                  }}</template>
                  <template #cell(info)="data">{{
                    data.item.info ? data.item.info : ""
                  }}</template>
                  <template #cell(result)="data">
                    <span
                      :class="`text-light p-1  ${
                        data.item.result === '99'
                          ? ''
                          : colorResult(Number(data.item.result))
                      }`">
                      {{ result(data.item.result) }}</span
                    >
                  </template>
                  <template #cell(status)="data">
                    {{ data.item.result !== "99" ? "Closed" : "Opened" }}
                  </template>
                </b-table>
                <div v-if="!totalRegister" class="text-center">
                  {{ $t("tableReports.noResults") }}
                </div>
              </div>
            </b-overlay>

            <p
              class="text-center my-2"
              v-if="searchActive && !tableData.length && !loading">
              {{ $t("tableReports.noResults") }}
            </p>

            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length > limit"></button>
                  </ul>
                  <!-- <pagination
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";

import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import Swal from "sweetalert2";
import Tooltip from "../../../../components/tooltip.vue";
import DateRange from "../components/DateRange.vue";
import { mapGetters } from "vuex";
// import Pagination from "../../../../components/pagination.vue";
// import TotalRegister from "../../../../components/totalRegister.vue";

export default {
  name: "Provider-logs",
  components: {
    Layout,
    PageHeader,
    downloadexcel,
    /* TotalRegister, */ Tooltip,
    DateRange,
  },
  computed: {
    ...mapGetters({
      result: "reports/formatRouletteResults",
      colorResult: "reports/colorResult",
    }),
  },
  data() {
    return {
      //
      searchActive: false,
      fetching: false,
      //

      title: this.$t("sidebar.reports.subItems.providerResults"),
      items: breadCrumbData.providerLogs,
      disabledNextPage: null,
      disabledPreviousPage: null,
      filterData: {},
      clients: [],
      operators: [],
      roulettes: [],
      currencies: [],
      fileName: "ProviderLogs.xls",
      json_fields: {
        Round: "round",
        "Ruleta ID (Proveedor)": "id_roulette",
        "Round ID (Proveedor)": "id_round",
        Fecha: "date",
        Result: "result",
        Estatus: "status",
        Error: "error",
        Rpm: "rpm",
        Giro: "spin",
      },
      fields: [
        {
          label: "ID".toUpperCase(),
          key: "round",
          class: "space-nowrap ",
        },
        {
          label: this.$t("providerResults.rouletteId").toUpperCase(),
          key: "id_roulette",
          class: "space-nowrap ",
        },
        {
          label: this.$t("providerResults.roundId").toUpperCase(),
          key: "id_round",
          class: "space-nowrap ",
        },
        {
          label: "Result".toUpperCase(),
          key: "result",
          class: "space-nowrap ",
        },
        {
          label: this.$t("tableReports.status").toUpperCase(),
          key: "status",
          class: "space-nowrap ",
        },
        {
          label: this.$t("tableReports.date").toUpperCase(),
          key: "dateFormatted",
          class: "space-nowrap ",
        },
        {
          label: this.$t("tableReports.error").toUpperCase(),
          key: "error",
          class: "space-nowrap ",
        },
        { label: "Rpm".toUpperCase(), key: "rpm", class: "space-nowrap " },
        { label: "Giro".toUpperCase(), key: "spin", class: "space-nowrap " },
        {
          label: this.$t("providerResults.errorTitle").toUpperCase(),
          key: "title",
          class: "space-nowrap ",
        },
        {
          label: this.$t("providerResults.errorDesc").toUpperCase(),
          key: "info",
          class: "space-nowrap ",
        },
      ],
      totalRegister: 0,
      totalPages: "...",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["round", "id_round"],
      loading: false,
      tableData: [],
      sortBy: "dateFormatted",
      sortDesc: true,
      skip: 0,
      limit: 10,
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
      // downloadSelected: "true",
      gameTypes: ["Roulette"],
      gameType: null,
      userRole: "",
    };
  },
  methods: {
    async handleSearch() {
      this.filterData.round = this.filter;
      await this.filtrar();
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    getData() {
      this.searchActive = true;

      this.loading = true;
      this.$http
        .get("/reports/provider-logs", {
          params: {
            limit: this.limit,
            page: this.currentPage,
            // pagination: "true", //INFO: Pagination en true para poder paginar, este valor cambiará para el download excel
          },
        })
        .then(({ data }) => {
          if (data.ok) {
            this.tableData = data.results;
            // this.disabledNextPage = data.results.hasNextPage;
            // this.disabledPreviousPage = data.results.hasPrevPage;
            // this.totalPages = data.results.totalPages;
            this.totalRegister = data.results.length;
          } else {
            this.totalRegister = 0;
            this.tableData = [];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRoulettes() {
      this.$http
        .get("/roulettes/fisics/get")
        .then(({ data }) => {
          if (data.ok) {
            this.roulettes = data.rouletteFisics;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    change() {
      this.filtrar();
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t("helpers.onPage"):
          break;
        case this.$t("helpers.today"): {
          const initialDate = new Date().toJSON().split("T")[0];
          this.filterData = {
            ...this.filterData,
            fromDate: initialDate,
            toDate: initialDate,
          };
          break;
        }
        // case this.$t('helpers.week'): {
        //   console.log('DESCARGAR', this.$t('helpers.week'));
        //   const currentDay = new Date().getDate();
        //   const dayPos = new Date().getDay();

        //   let from;
        //   let til;

        //   if (currentDay < 7) {
        //     return console.log('Buscar hacia el mes anterior',);
        //   }
        //   if (currentDay > 26) {
        //     return console.log('Buscar hacia el mes siguiente',);
        //   }

        //   from = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${currentDay - dayPos}`).toJSON().split('T')[0];
        //   til = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${(6 - dayPos) + currentDay}`).toJSON().split('T')[0];

        //   console.log('Buscar en este mes', from, til);

        //   // const month = new Date().getMonth();
        //   // let currentMonthDays = this.daysInMonth(month, new Date().getFullYear());
        //   // const initialDate = new Date().toJSON().split("T")[0]
        //   // console.log('Buscar en', currentMonthDays);
        //   break;
        // }
        case this.$t("helpers.month"): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split("T")[0].split("-");
          let fromDate = [initialDate[0], initialDate[1], from].join("-");
          let toDate = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async exportData() {
      if (!this.filterData.fromDate || !this.filterData.toDate)
        return Swal.fire(
          "Es necesario seleccionar un rango de fecha",
          "",
          "info"
        );

      this.fetching = true;

      const { data } = await this.$http.get("/reports/provider-logs", {
        params: {
          ...this.filterData,
          limit: this.limit,
          page: this.currentPage,
          // pagination: this.downloadSelected,
        },
      });
      if (data.ok) {
        const formattedData = [];

        if (data.results.length) {
          data.results.forEach((element) => {
            formattedData.push({
              ...element,
              date: new Date(element.date).toUTCString(),
              round: element.roundProvider[0].identifierNumber,
              result: element.result === "99" ? "" : element.result,
              status: element.result === "99" ? "Abierta" : "Cerrada",
            });
          });
        }

        this.fetching = false;

        return formattedData;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false;
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    filtrar() {
      this.searchActive = true;

      this.loading = true;
      this.$http
        .get(`/reports/provider-logs`, {
          params: {
            limit: this.limit,
            page: this.currentPage,
            // pagination: "true", //INFO: Pagination en true para poder paginar, este valor cambiará para el download excel

            ...this.filterData,
          },
        })
        .then(({ data }) => {
          if (data.ok) {
            this.tableData = data.results;
            // this.disabledNextPage = data.results.hasNextPage;
            // this.disabledPreviousPage = data.results.hasPrevPage;
            // this.totalPages = data.results.totalPages;
            this.totalRegister = data.results.length;
          } else {
            this.totalRegister = 0;
            this.tableData = [];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelFilter() {
      this.searchActive = false;
      this.filterData = {};
      // this.getData();
    },
  },
  mounted() {
    // this.getData();
    this.getRoulettes();
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
  },
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  right: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}

.black {
  background: #000;
}

.red {
  background: #f10;
}

.green {
  background: #00a429;
}
</style>
